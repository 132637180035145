<template>
	<scTable v-loading="isLoading" ref="table" :data="tableData" :staticTotal="total" height="470px"
		:pageSize="queryInfo.pageSize" stripe @pagination-change="paginationChange" @page-size-change="pageSizeChange"
		:select-on-indeterminate="false">
		<el-table-column label="#" width="55">
			<template #default="{ row }">
				<el-radio :value="`/packageGoods/detail/index?id=${row.id}`" v-model="url">&nbsp;&nbsp;</el-radio>
			</template>
		</el-table-column>
		<el-table-column label="商品图" width="100" align="center">
			<template #default="scope">
				<div class="cell-image">
					<el-avatar :src="scope.row.image"></el-avatar>
				</div>
			</template>
		</el-table-column>
		<el-table-column label="商品名称" prop="title" align="center" min-width="150"></el-table-column>
	</scTable>
</template>
<script>
export default {
	props: {
		modelValue: {
			type: String,
			default: ""
		},
	},
	data() {
		return {
			tableData: [],
			total: 0,
			url: "",
			queryInfo: {
				pageNo: 1,
				pageSize: 10,
				keywords: ""
			},
			isLoading: false
		}
	},
	mounted() {
		this.getDataList()
	},
	watch: {
		modelValue: {
			handler(newVal) {
				this.url = newVal
			},
			immediate: true,
			deep: true
		},

		url(val) {
			this.$emit('update:modelValue', val);
		}
	},
	methods: {
		//! 分页功能
		paginationChange(page) {
			this.queryInfo.pageNo = page
			this.getDataList()
		},
		//! 页码切换
		pageSizeChange(size) {
			this.queryInfo.pageSize = size
			this.getDataList()
		},
		async getDataList() {
			this.isLoading = true
			let res = await this.$API.goods.goods.list.get(this.queryInfo)
			this.isLoading = false
			if (res?.code === 1) {
				this.tableData = res?.data?.data || []
				this.total = res?.data?.total || 0
			}
		},
	}
}
</script>
