<template>
	<el-input :size="size" :placeholder="placeholder" :value="modelValue" readonly @click="openSelect"></el-input>
	<Dialog v-if="visible" ref="Dialog" v-model="url" @submit="submit" @clear="clear" @closed="visible = false" />
</template>

<script>
import Dialog from "./dialog"
export default {
	props: {
		modelValue: {
			type: String,
			default: ""
		},
		size: {
			type: String,
			default: "default"
		},
		placeholder: {
			type: String,
			default: "链接"
		}
	},
	components: {
		Dialog
	},
	data() {
		return {
			visible: false,
			isSaveing: false,
			url: "",
		}
	},
	watch: {
		modelValue: {
			handler(newVal) {
				this.url = newVal
			},
			immediate: true,
			deep: true
		},
	},
	methods: {
		openSelect() {
			this.visible = true
			this.$nextTick(() => {
				console.log(this.$refs.Dialog)
				this.$refs.Dialog.open()
			})
		},
		//表单提交方法
		submit() {
			this.isSaveing = true
			this.$emit('update:modelValue', this.url);
			this.isSaveing = false
			this.visible = false
		},
		clear() {
			this.isSaveing = true
			this.$emit('update:modelValue', '');
			this.isSaveing = false
			this.visible = false
		}
	}
}
</script>

<style lang="scss" scoped></style>
