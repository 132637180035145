<template>
	<el-dialog title="选择链接" v-model="visible" align-center :width="1000" destroy-on-close @closed="$emit('closed')">
		<el-container>
			<el-aside width="171px" style="height:470px">
				<el-tree :data="data" :props="defaultProps" @node-click="handleNodeClick">
					<template #default="{ node, data }">
						<span :class="{ empty: true, checked: data?.components === selectComponents }">
							<span>{{ node.label }}</span>
						</span>
					</template>
				</el-tree>
			</el-aside>
			<el-main>
				<template v-if="selectComponents">
					<component :is="selectComponents" v-model="url"></component>
				</template>
				<template v-else>
					<div class="panel">
						请在左侧选择路径分类
					</div>
				</template>
			</el-main>
		</el-container>
		<template #footer>
			<el-button @click="visible = false">取 消</el-button>
			<el-button type="danger" @click="$emit('clear')">清 空</el-button>
			<el-button type="primary" @click="$emit('submit')">保 存</el-button>
		</template>
	</el-dialog>
</template>

<script>
import Component from "./components"
export default {
	props: {
		modelValue: {
			type: String,
			default: ""
		},
	},
	components: {
		...Component
	},
	data() {
		return {
			visible: false,
			url: "",
			selectComponents: "Link",
			data: [
				{
					label: '商城链接',
					components: "Link"
				},
				{
					label: '商品页面',
					components: "Goods"
				},
			],
			defaultProps: {
				children: 'children',
				label: 'label',
			}
		}
	},
	watch: {
		modelValue: {
			handler(newVal) {
				this.url = newVal
			},
			immediate: true,
			deep: true
		},
		url(val) {
			this.$emit('update:modelValue', val);
		}
	},
	methods: {
		handleNodeClick(data) {
			if (!data.components) return
			this.selectComponents = data.components
		},
		open() {
			this.visible = true
			return this
		}
	}
}
</script>

<style lang="scss" scoped>
.panel {
	height: 470px;
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 18px;
	color: #b2b2b2;
}

.checked {
	color: var(--el-color-primary);
}

.empty {
	font-weight: bold;
}
</style>
