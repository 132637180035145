<template>
	<div class="box-panel">
		<div v-for="item, index in routeList" :key="index">
			<div class="cont">{{ item.label }}</div>
			<div class="cell">
				<div :class="{ tag: true, checked: route === i.value }" v-for="i, i_index in item.children" :key="i_index"
					@click="onChange(i.value)">{{ i.label }}</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		modelValue: {
			type: String,
			default: ""
		},
	},
	data() {
		return {
			route: "",
			routeList: [
				{
					label: "基础链接",
					children: [{
						label: '领券中心',
						value: "/packageMarket/coupon/index"
					}, {
						label: '关于我们',
						value: "/pages/agreement/aboutus"
					}, {
						label: '关于财神',
						value: "/pages/agreement/aboutmammon"
					}]
				},
				{
					label: "分销",
					children: [{
						label: "服务中心",
						value: "/packageService/identity/index"
					}, {
						label: "分销中心",
						value: '/packageAgent/center/index'
					}, {
						label: "合作咨询",
						value: '/packageService/cooperation/index'
					}]
				},
			]
		}
	},
	mounted() {
	},
	watch: {
		modelValue: {
			handler(newVal) {
				this.route = newVal
			},
			immediate: true,
			deep: true
		},
		route(val) {
			this.$emit('update:modelValue', val);
		}
	},
	methods: {
		onChange(val) {
			this.route = val
		}
	}
}
</script>
<style lang="scss" scoped>
.box-panel {
	height: 470px;

	.cont {
		color: #000;
		font-weight: 700;
	}

	.cell {
		display: flex;
		flex-wrap: wrap;
		margin-top: 19px;

		.tag {
			font-weight: 400;
			color: rgba(0, 0, 0, .85);
			background: #fafafa;
			border-radius: 3px;
			text-align: center;
			padding: 7px 30px;
			margin-right: 10px;
			margin-bottom: 18px;
			cursor: pointer;

			&:hover {
				background-color: #eee;
				color: #333;
			}
		}

		.checked {
			background-color: #2d8cf0 !important;
			color: #fff !important;
		}
	}
}
</style>
