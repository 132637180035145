import config from "@/config";
import http from "@/utils/request";

export default {
	device: {
		category: {
			list: {
				url: `${config.API_URL}/device_category/list`,
				name: "获取设备分类列表",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
			addAndEdit: {
				url: `${config.API_URL}/device_category/store`,
				name: "新增编辑设备分类",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			delete: {
				url: `${config.API_URL}/device_category/del`,
				name: "删除设备分类",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
		},
		device: {
			list: {
				url: `${config.API_URL}/device/list`,
				name: "获取设备列表",
				get: async function (data = {}, config = {}) {
					return await http.get(this.url, data, config);
				},
			},
			addAndEdit: {
				url: `${config.API_URL}/device/store`,
				name: "新增编辑设备",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			delete: {
				url: `${config.API_URL}/device/del`,
				name: "删除设备",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
			detail: {
				url: `${config.API_URL}/device/detail`,
				name: "获取设备详情",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
		},
	},
	activity: {
		list: {
			url: `${config.API_URL}/navigation/list`,
			name: "获取活动列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/navigation/store`,
			name: "新增编辑活动",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/navigation/del`,
			name: "删除活动",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/navigation/detail`,
			name: "获取活动详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
	community: {
		list: {
			url: `${config.API_URL}/community/getArticleList`,
			name: "获取社区列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/community/getArticle`,
			name: "获取社区详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		commentList: {
			url: `${config.API_URL}/community/getArticleComment`,
			name: "获取社区评论列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		auditArticle: {
			url: `${config.API_URL}/community/auditArticle`,
			name: "审核社区内容",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		likeList: {
			url: `${config.API_URL}/community/getArticleLike`,
			name: "获取社区点赞列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/community/delArticle`,
			name: "删除社区内容",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		delComment: {
			url: `${config.API_URL}/community/delArticleComment`,
			name: "删除社区评论",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		article: {
			list: {
				url: `${config.API_URL}/community/getTimerArticleList`,
				name: "获取定时发布列表",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
			addAndEdit: {
				url: `${config.API_URL}/community/storeArticle`,
				name: "新增编辑社区内容",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			user: {
				url: `${config.API_URL}/community/getArticleUser`,
				name: "自动生成用户",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
			detail: {
				url: `${config.API_URL}/community/getArticle`,
				name: "获取社区详情",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
			virtualUserList: {
				url: `${config.API_URL}/community/virtualUserList`,
				name: "虚拟用户列表",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
		},
		rank: {
			list: {
				url: `${config.API_URL}/article_activity/list`,
				name: "获取社区排行榜",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
			addAndEdit: {
				url: `${config.API_URL}/article_activity/store`,
				name: "新增/编辑社区排行榜",
				post: async function (data = {}) {
					return await http.post(this.url, data);
				},
			},
			detail: {
				url: `${config.API_URL}/article_activity/detail`,
				name: "获取社区排行榜详情",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
			delete: {
				url: `${config.API_URL}/article_activity/del`,
				name: "删除社区排行榜",
				get: async function (data = {}) {
					return await http.get(this.url, data);
				},
			},
		},
	},
	banner: {
		list: {
			url: `${config.API_URL}/banner/list`,
			name: "获取banner列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/banner/store`,
			name: "新增编辑banner",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/banner/del`,
			name: "删除banner",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
	products: {
		list: {
			url: `${config.API_URL}/page_products/list`,
			name: "获取商品列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/page_products/store`,
			name: "新增编辑商品",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/page_products/del`,
			name: "删除banner",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/page_products/detail`,
			name: "获取活动详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
	king: {
		list: {
			url: `${config.API_URL}/king/list`,
			name: "获取导航列表",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		addAndEdit: {
			url: `${config.API_URL}/king/store`,
			name: "新增编辑导航",
			post: async function (data = {}) {
				return await http.post(this.url, data);
			},
		},
		delete: {
			url: `${config.API_URL}/king/del`,
			name: "删除导航",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
		detail: {
			url: `${config.API_URL}/king/detail`,
			name: "获取导航详情",
			get: async function (data = {}) {
				return await http.get(this.url, data);
			},
		},
	},
};
